/*
Copyright 2017-present Palantir Technologies, Inc. All rights reserved.
Licensed under the Apache License, Version 2.0.
*/
@font-face {
  font-family: "blueprint-icons-16";
  src: url("./blueprint-icons-16.ttf?141c37d6ab6c8c3e1bd4e5eaab0cac02") format("truetype"), url("./blueprint-icons-16.eot?141c37d6ab6c8c3e1bd4e5eaab0cac02#iefix") format("embedded-opentype"), url("./blueprint-icons-16.woff2?141c37d6ab6c8c3e1bd4e5eaab0cac02") format("woff2"), url("./blueprint-icons-16.woff?141c37d6ab6c8c3e1bd4e5eaab0cac02") format("woff"), url("./blueprint-icons-16.svg?141c37d6ab6c8c3e1bd4e5eaab0cac02#blueprint-icons-16") format("svg");
}
@font-face {
  font-family: "blueprint-icons-20";
  src: url("./blueprint-icons-20.ttf?b3487eb25dedb5a8cee094896fb5d2b7") format("truetype"), url("./blueprint-icons-20.eot?b3487eb25dedb5a8cee094896fb5d2b7#iefix") format("embedded-opentype"), url("./blueprint-icons-20.woff2?b3487eb25dedb5a8cee094896fb5d2b7") format("woff2"), url("./blueprint-icons-20.woff?b3487eb25dedb5a8cee094896fb5d2b7") format("woff"), url("./blueprint-icons-20.svg?b3487eb25dedb5a8cee094896fb5d2b7#blueprint-icons-20") format("svg");
}