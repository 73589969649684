.fullscreen-map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.fullheight-map {
  height: 100vh;
}

.fixed-500px-height-map {
  height: 500px;
}
