body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table.bp5-html-table {
  width: 100%;
}

.bp5-dialog-header {
  background: #051624 !important;
  box-shadow: 0 1px 0 rgba(16, 22, 26, 1) !important;
}

.bp5-dialog-header h6 {
  color: white;
}

.bp5-dialog {
  background: white !important;
}

.bp5-dialog-body {
  margin: 20px 20px 0 20px !important;
}

.pulse {
  animation: pulse-animation 1.3s infinite;
}

/* rgba(217, 130, 43, 1) is the blueprintjs' WARNING intent */
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(217, 130, 43, 1);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
